<template>
  <span data-cy="cluster-selection">
    <h4 class="mt-40 mb-22 txt-bold txt-font-din-medium">{{ $t('components.titles.clusterSelection').toUpperCase() }}</h4>
    <a-card :bordered="true" class="mb-22">
      <a-form layout="vertical">
        <div class="txt-18 txt-font-din-medium txt-bold txt-black">
          {{ $t('components.labels.clusterCalculation') }}&nbsp;&nbsp;
          <a-tooltip class="ml-8">
            <div slot="title">
              {{ $t('components.toolTips.clusterCalculation') }}
            </div>
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
          <div v-if="!isRapidSmart" class="txt-16 txt-font-din-regular txt-grey mt-20">
            {{ $t('components.description.calculationForNumberOfHouseholdsPerDayPerTeam') }}
          </div>
        </div>
        <div v-if="!isRapidSmart || (isRapidSmart && isExhaustive) || (isRapidSmart && isRandom)">
          <div class="mt-20 divider divider-bottom pt-8
            pb-8 w635 flex justify-space-between align-center">
            <div>{{ $t('components.description.totalTimePerDayForFieldWork') }}</div>
            <div>
              <a-input-number v-model="cluster.fieldworkTime"
                :disabled="isSupervisor"
                size="large" :min="0" class="mr-8 w60"
                data-cy="cluster.fieldworkTime"/>
              {{ $tc('values.minute', 2) }}
            </div>
          </div>
          <div class="divider-bottom pt-8 pb-8 w635 flex justify-space-between align-center">
            <div>{{ $t('components.description.travelTimeToClusterLocation') }}</div>
            <div>
              <a-input-number v-model="cluster.clusterTravelTime"
                :disabled="isSupervisor"
                size="large" :min="0" class="mr-8 w60"
                data-cy="cluster.clusterTravelTime"/>
              {{ $tc('values.minute', 2) }}
            </div>
          </div>
          <div class="divider-bottom pt-8 pb-8 w635 flex justify-space-between align-center">
            <div>{{ $t('components.description.durationForInitialIntroductionAndSelectionOfHh') }}</div>
            <div>
              <a-input-number v-model="cluster.duration"
                :disabled="isSupervisor"
                size="large" :min="0" class="mr-8 w60"
                data-cy="cluster.duration"/>
              {{ $tc('values.minute', 2) }}
            </div>
          </div>
          <div class="divider-bottom pt-8 pb-8 w635 flex justify-space-between align-center">
            <div>{{ $t('components.description.totalDurationOfBreaks') }}</div>
            <div>
              <a-input-number v-model="cluster.breaks"
                :disabled="isSupervisor"
                size="large" :min="0" class="mr-8 w60"
                data-cy="cluster.breaks"/>
              {{ $tc('values.minute', 2) }}
            </div>
          </div>
          <div class="divider-bottom pt-8 pb-8 w635 flex justify-space-between align-center">
            <div>{{ $t('components.description.travelTimeFromOneHhToAnother') }}</div>
            <div>
              <a-input-number v-model="cluster.householdTravelTime"
                :disabled="isSupervisor"
                size="large" :min="0" class="mr-8 w60"
                data-cy="cluster.householdTravelTime"/>
              {{ $tc('values.minute', 2) }}
            </div>
          </div>
          <div class="pt-8 pb-8 w635 flex justify-space-between align-center">
            <div>{{ $t('components.description.averageTimeInTheHh') }}</div>
            <div>
              <a-input-number v-model="cluster.timeAtHousehold"
                :disabled="isSupervisor"
                size="large" :min="0" class="mr-8 w60"
                data-cy="cluster.timeAtHousehold"/>
              {{ $tc('values.minute', 2) }}
            </div>
          </div>
          <div class="cluster-results">
            <a-row>
              <a-col :lg="11" :md="24">
                <span class="mr-24 txt-bold txt-20" data-cy="householdPerDayTeam">{{ householdPerDayTeam }}</span>
                {{ $t('components.description.numberOfHhPlannedDayTeam') }}
              </a-col>
            </a-row>
          </div>
        </div>
        <div
          v-if="!isExhaustive && !isRandom"
          class="txt-16 txt-font-din-regular txt-grey mt-40">
          {{ $t('components.description.calculationForNumberOfClusters') }}
        </div>
        <div v-if="!isExhaustive && !isRandom" class="flex align-center mt-8">
          <div class="txt-16">
            <a-input-number v-model="cluster.householdSampleSize"
              :disabled="isRapidSmart || isSupervisor"
              size="large" :min="0" class="mr-8 w60"
              data-cy="cluster.householdSampleSize"/>
            {{ $t('components.description.householdSampleSize') }}
          </div>
          <span class="txt-bold txt-20 ml-8 mr-8">/</span>
          <div class="txt-16">
            <a-input-number v-model="cluster.householdDayTeam"
              :disabled="isSupervisor"
              size="large" :min="0" class="mr-8 w60"
              data-cy="cluster.householdDayTeam"/>
            {{ $t('components.description.householdDayTeam') }}
          </div>
          <span class="txt-bold txt-20 ml-8 mr-8">=</span>
          <div><span class="txt-bold txt-20" data-cy="numberOfClusters">{{ numberOfClusters }} </span>{{ $t('components.description.numberOfClusters') }}</div>
        </div>
      </a-form>
      <div v-if="!isExhaustive && !isRandom" class="cluster-assignment-container">
        <ClusterAssignment
          :on-change="onChange"
          :size="size.correctionSize"
          :cluster-count="numberOfClusters"
          class="mt-40"/>
      </div>
    </a-card>
  </span>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'MetadataClusterSelection',
  components: {
    ClusterAssignment: () => import('../cluster-assignment/cluster-assignment.vue')
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    size: {
      type: Object,
      default: () => {}
    },
    sampling: {
      type: Object,
      default: () => {}
    },
    onSave: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      firstLoad: true,
      cluster: {
        assignments: undefined,
        fieldworkTime: undefined,
        clusterTravelTime: undefined,
        duration: undefined,
        breaks: undefined,
        householdTravelTime: undefined,
        timeAtHousehold: undefined,
        householdSampleSize: undefined,
        householdDayTeam: undefined
      }
    };
  },
  computed: {
    ...mapGetters([
      'isRapidSmart',
      'isExhaustive',
      'isRandom'
    ]),
    ...mapState({
      metadata: state => state.survey.metadata,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    householdPerDayTeam() {
      const {
        fieldworkTime,
        clusterTravelTime,
        duration,
        breaks,
        householdTravelTime,
        timeAtHousehold
      } = this.cluster;

      if (fieldworkTime && clusterTravelTime &&
        duration && breaks && householdTravelTime && timeAtHousehold) {
        const value = Math.floor((fieldworkTime - clusterTravelTime - duration - breaks) /
          (householdTravelTime + timeAtHousehold));
        return Number.isNaN(value) ? 0 : value;
      }
      return 0;
    },
    numberOfClusters() {
      if (this.isRapidSmart) {
        return 25;
      }

      const value = Math.ceil(this.cluster.householdSampleSize / this.cluster.householdDayTeam);
      if (Number.isFinite(value)) {
        return value;
      }

      return 0;
    }
  },
  watch: {
    householdPerDayTeam(value) {
      if (!this.firstLoad) {
        this.cluster.householdDayTeam = value;
      }
    },
    size: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.antroHouseholdsToInclude !== oldValue.antroHouseholdsToInclude ||
        newValue.mortalityHouseholdIncluded !== oldValue.mortalityHouseholdIncluded) {
          const max = Math.max(
            ...[this.size.antroHouseholdsToInclude, this.size.mortalityHouseholdIncluded]
          );
          this.cluster.householdSampleSize = max.toFixed(0);
        }
      }
    },
    cluster: {
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(function() {
        this.$emit('input', {
          ...this.cluster,
          numberOfClusters: this.numberOfClusters,
          householdPerDayTeam: this.householdPerDayTeam
        });
        if (!this.firstLoad) this.onSave();
        this.firstLoad = false;
      }, 1000)
    }
  },
  created() {
    this.setDataFromStore();
    if (this.isRapidSmart) {
      this.cluster.householdSampleSize = undefined;
    }
    this.$emit('input', this.cluster);
  },
  methods: {
    getHouseholdSampleSize() {
      const max = Math.max(
        ...[this.size.antroHouseholdsToInclude, this.size.mortalityHouseholdIncluded]
      );
      return this.metadata.householdSampleSize != null ? this.metadata.householdSampleSize : max.toFixed(0);
    },
    setDataFromStore() {
      const {
        fieldworkTime,
        clusterTravelTime,
        duration,
        breaks,
        householdTravelTime,
        timeAtHousehold,
        householdSampleSize,
        householdDayTeam
      } = (this.metadata || {});
      this.cluster = {
        fieldworkTime,
        clusterTravelTime,
        duration,
        breaks,
        householdTravelTime,
        timeAtHousehold,
        householdSampleSize,
        householdDayTeam
      };
    },
    onChange(clusters, justification, save) {
      this.$emit('input', {
        ...this.cluster,
        clusters,
        clusterJustification: justification,
        numberOfClusters: this.numberOfClusters,
        householdPerDayTeam: this.householdPerDayTeam
      });
      if (save) {
        this.onSave();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cluster-results {
  width: 640px;
  padding: 10px 20px 10px;
  border-bottom: 1px solid #cfd0cf;
  border-top: 1px solid #cfd0cf;
  background: #f4f4f4;
}

.cluster-assignment-container {
  width: 635px;
}

@media (max-width: 900px) {
  .cluster-results {
    width: 100%;
  }
}
</style>
